<script>
const PORTFOLIO_DEFAULT_URL = '/portfolio/';
// const SHOWROOM_DEFAULT_URL = '/showroom/';

export default {
  name: 'portfolio-showroom-header',
  props: {
    lang: String,
    active: String,
    profile: Object,
    urls: {
      type: Object,
      default: () => ({
        de: '/de/',
        en: '/en/',
        login: '/login/',
        logout: '/logout/',
        postUrl: null,
        postNext: null,
        portfolio: PORTFOLIO_DEFAULT_URL,
        // showroom: SHOWROOM_DEFAULT_URL,
      }),
    },
    postLang: {
      type: Boolean,
      default: false,
    },
    postCsrf: {
      type: String,
      default: '',
    },
    emitNavigation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuOrder: [
        { id: 1, cls: 'portfolio', url: (this.urls.portfolio === undefined) ? PORTFOLIO_DEFAULT_URL : this.urls.portfolio },
        // { id: 2, cls: 'showroom', url: this.urls.showroom },

      ],
      scrollPosition: null,
    };
  },
  computed: {
    menuItems() {
      const currentItems = this.menuOrder.filter(
        (el) => (
          !el.permissions
          || (
            el.permissions
            && this.profile
            && this.profile.permissions
            && Array.isArray(this.profile.permissions)
            && this.profile.permissions.some((p) => el.permissions.includes(p))
          )
        ),
      );
      if (this.active) {
        const a = this.active;
        return currentItems.filter((el) => el.cls === a)
          .concat(currentItems.filter((el) => el.cls !== a));
      }
      return currentItems;
    },
    notSupports() {
      return !('CSS' in window) || !('supports' in window.CSS);
    },
    profileInitials() {
      if (this.profile && this.profile.name) {
        return this.profile.name.replace(/\./g, '').replace(/\W*(\w)\w*/g, '$1').toUpperCase();
      }
      return '';
    },
  },
  watch: {
    lang: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          if (['de', 'en'].indexOf(val) !== -1) {
            this.$i18n.locale = val;
          } else {
            this.$i18n.locale = 'de';
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    emitNavigate(e, to) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('navigate', to);
    },
    handleScroll() {
      this.scrollPosition = window.pageYOffset;
    },
    nav(e, to) {
      if (this.profile) {
        if (to === 'portfolio' && this.active !== 'portfolio') {
          e.preventDefault();
          e.stopPropagation();
          window.location.href = `${this.menuOrder.filter((el) => el.cls === to)[0].url}accounts/login/`;
        }
      }
    },
    toggleProfile() {
      if (this.$refs.profileCheckbox) {
        this.$refs.profileCheckbox.checked = this.$refs.profileCheckbox.checked !== true;
      }
    },
    closeAll() {
      if (this.$refs.profileCheckbox) this.$refs.profileCheckbox.checked = false;
      if (this.$refs.menuCheckbox) this.$refs.menuCheckbox.checked = false;
    },
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  directives: {
    tooltip: {
      bind(el) {
        const s = el.style;
        s.textOverflow = 'ellipsis';
        s.whiteSpace = 'nowrap';
        s.overflow = 'hidden';
        el.addEventListener('mouseover', (evt) => {
          const targetEl = evt.target;
          if (targetEl.offsetWidth < targetEl.scrollWidth) {
            targetEl.setAttribute('title', targetEl.textContent);
          } else {
            // eslint-disable-next-line no-unused-expressions
            targetEl.hasAttribute('title') && targetEl.removeAttribute('title');
          }
        });
      },
    },
  },
};
</script>

<i18n src="../locales/base-header/de.json"></i18n>
<i18n src="../locales/base-header/en.json"></i18n>

<template>
<div
  v-click-outside="closeAll"
  :class="{
    'not-supports': notSupports,
    'fixed-small': scrollPosition >= 8,
    fixed: scrollPosition >= 16
  }"
  class="navwrapper"
>
  <nav>
    <div class="base-header-ajaxloader hidden">
      <span v-for="n in 5" :key="n"></span>
    </div>
    <input
      v-if="profile"
      id="profile-checkbox"
      type="checkbox"
      aria-hidden="true"
      ref="profileCheckbox"
    >
    <ul>
      <li class="profile">
        <label
          v-if="profile"
          for="profile-checkbox"
          role="button"
          @keydown.enter="toggleProfile"
          tabindex="0"
        >
          <span class="fullname">
            {{ profile.name }} <img alt="" src="../assets/img/icon-down.svg">
          </span>
          <span class="initials">
            {{ profileInitials }} <img alt="" src="../assets/img/icon-down.svg">
          </span>
        </label>
        <a
          v-else
          v-on="emitNavigation ? { click: $event => emitNavigate($event, 'login') } : {}"
          :href="urls.login"
        >
          <span v-t="'login'"></span>
        </a>
      </li>
      <li
        v-for="item in menuItems"
        :key="item.id"
        :class="[{ active: active === item.cls }, item.cls]"
        class="navitem"
      >
        <a
          @click="nav($event, item.cls)"
          :href="item.url"
          :target="item.external ? '_blank' : null"
          :rel="item.external ? 'noopener' : null"
        >
          <span>
            <img aria-hidden="true" :src="require('../assets/img/icons/' + item.cls + '.svg')">
            {{ $t(item.cls) }}
          </span>
        </a>
      </li>
    </ul>
    <div v-if="profile" class="profile-box">
      <div class="info">
        <div class="name">{{ profile.name }}</div>
        <div v-if="profile.email" v-tooltip>{{ profile.email }}</div>
        <template v-if="Array.isArray(profile.info)">
          <div v-for="(item, index) in profile.info" :key="index" v-tooltip>{{ item }}</div>
        </template>
      </div>
      <ul>
        <li v-if="false">
          <a href="#" v-t="'profile'"></a>
        </li>
        <li v-if="postLang && postCsrf && urls.postUrl">
          <form method="post" :action="urls.postUrl">
            <input type="hidden" name="csrfmiddlewaretoken" :value="postCsrf">
            <input v-if="urls.postNext" type="hidden" name="next" :value="urls.postNext">
            <input type="hidden" name="language" :value="lang === 'en' ? 'de' : 'en'">
            <template v-if="lang === 'de'">
              <span>DE</span>
              <button type="submit">EN</button>
            </template>
            <template v-else>
              <button type="submit">DE</button>
              <span>EN</span>
            </template>
          </form>
        </li>
        <li v-else>
          <a
            v-on="emitNavigation ? { click: $event => emitNavigate($event, 'de') } : {}"
            :class="{ active: lang === 'de' }"
            :href="urls.de"
            class="lang"
          >DE</a>
          <a
            v-on="emitNavigation ? { click: $event => emitNavigate($event, 'en') } : {}"
            :class="{ active: lang === 'en' }"
            :href="urls.en"
            class="lang"
          >EN</a>
        </li>
        <li>
          <a
            v-on="emitNavigation ? { click: $event => emitNavigate($event, 'logout') } : {}"
            :href="urls.logout"
            v-t="'logout'"
          ></a>
        </li>
      </ul>
    </div>
  </nav>
</div>
</template>

<style lang="scss" src="../scss/base-header.scss" scoped></style>
