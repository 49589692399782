<script>
export default {
  name: 'portfolio-showroom-footer',
  props: {
    lang: String,
    urls: {
      type: Object,
      default: () => ({
        de: '/de/',
        en: '/en/',
        login: '/login/',
        logout: '/logout/',
        postUrl: null,
        postNext: null,
        terms: '/terms/',
        siteNotice: '/site-notice/',
      }),
    },
    postLang: {
      type: Boolean,
      default: false,
    },
    postCsrf: {
      type: String,
      default: '',
    },
    emitNavigation: {
      type: Boolean,
      default: false,
    },
    loggedIn: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    lang: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          if (['de', 'en'].indexOf(val) !== -1) {
            this.$i18n.locale = val;
          } else {
            this.$i18n.locale = 'de';
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    emitNavigate(e, to) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('navigate', to);
    },
  },
};
</script>

<i18n src="../locales/base-footer/de.json"></i18n>
<i18n src="../locales/base-footer/en.json"></i18n>

<template>
  <footer>
    <ul>
      <li>
        <a
          v-if="loggedIn"
          v-on="emitNavigation ? { click: $event => emitNavigate($event, 'logout') } : {}"
          :href="urls.logout"
          v-t="'logout'"
        ></a>
        <a
          v-else
          v-on="emitNavigation ? { click: $event => emitNavigate($event, 'login') } : {}"
          :href="urls.login"
          v-t="'login'"
        ></a>
      </li>

      <li>
        <a href="https://portfolio-showroom.ac.at/" v-t="'about'"></a>
      </li>
      <li v-if="urls.terms">
        <a :href="urls.terms" v-t="'terms'"></a>
      </li>
      <li v-if="urls.siteNotice">
        <a :href="urls.siteNotice" v-t="'site-notice'"></a>
      </li>

      <li v-if="postLang && postCsrf && urls.postUrl">
        <form method="post" :action="urls.postUrl">
          <input type="hidden" name="csrfmiddlewaretoken" :value="postCsrf">
          <input v-if="urls.postNext" type="hidden" name="next" :value="urls.postNext">
          <input type="hidden" name="language" :value="lang === 'en' ? 'de' : 'en'">
          <template v-if="lang === 'de'">
            <span class="lang-margin">DE</span>
            <button type="submit">EN</button>
          </template>
          <template v-else>
            <button class="lang-margin" type="submit">DE</button>
            <span>EN</span>
          </template>
        </form>
      </li>
      <li v-else>
        <a
          v-on="emitNavigation ? { click: $event => emitNavigate($event, 'de') } : {}"
          :class="{ active: lang === 'de' }"
          :href="urls.de"
          class="lang"
        >DE</a>
        <a
          v-on="emitNavigation ? { click: $event => emitNavigate($event, 'en') } : {}"
          :class="{ active: lang === 'en' }"
          :href="urls.en"
          class="lang"
        >EN</a>
      </li>
    </ul>
  </footer>
</template>

<style lang="scss" src="../scss/base-footer.scss" scoped></style>
