import 'document-register-element'; // polyfill for older browsers
import Vue from 'vue';
import VueCustomElement from 'vue-custom-element';
import VueI18n from 'vue-i18n';
import vClickOutside from 'v-click-outside';
import BaseHeader from './components/BaseHeader.vue';
import BaseFooter from './components/BaseFooter.vue';

Vue.config.productionTip = false;

Vue.use(VueCustomElement);
Vue.use(VueI18n);
Vue.use(vClickOutside);

// register component(s)
Vue.customElement('portfolio-showroom-header', BaseHeader);
Vue.customElement('portfolio-showroom-footer', BaseFooter);
